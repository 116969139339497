import TablePagination from '@mui/material/TablePagination';

const BookmarksPagination = ({ pagination, onChangePage, onChangeRowsPerPage, loading }) => {
    return (
        <><TablePagination
            component="div"
            count={pagination.count}
            page={pagination.page}
            onPageChange={onChangePage}
            rowsPerPage={pagination.rowsPerPage}
            onRowsPerPageChange={onChangeRowsPerPage}
            hidden={loading}
        />
        </>
    );
};

export default BookmarksPagination;
