import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const SearchForm = ({ onSubmit }) => {
    const [searchInput, setSearchInput] = useState('');

    const handleChange = (event) => {
        setSearchInput(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(searchInput);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <TextField
                        label="Search"
                        fullWidth
                        variant="outlined"
                        value={searchInput}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 1, mb: 2 }}>
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Box >
    );
};

export default SearchForm;
