// TasksTable.js
import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';

const TasksGrid = ({ tasks, loading, pageSize = 50 }) => {
    const theme = useTheme();

    const tasksColumns = [
        {
            field: 'id',
            headerName: 'Link',
            width: 50,
            renderCell: (params) => (
                <a href={'/tasks/' + params.value} target="_blank" rel="noopener noreferrer">
                    {params.value}
                </a>
            ),
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 250,
            editable: true,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 450,
            editable: true,
        },
        {
            field: 'priority',
            headerName: 'Priority',
            type: 'number',
            width: 110,
            editable: true,
        },
        {
            field: 'due_date',
            headerName: 'Due Date',
            width: 250,
            editable: true,
        },
    ];

    const handleSelectionChange = (newSelection) => {
        console.log('Selected IDs:', newSelection);
    };

    const getRowClassName = (params) => {
        return params.row.is_completed ? 'completed' : '';
    };

    return (
        <Box>
            <small>Total Found: {tasks.length}</small>
            {tasks.length > 0 && (
                <DataGrid
                    rows={tasks}
                    columns={tasksColumns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: pageSize,
                            },
                        },
                    }}
                    density={`compact`}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={handleSelectionChange}
                    getRowClassName={getRowClassName} // Apply the class conditionally
                    sx={{
                        '& .MuiDataGrid-row.completed': {
                            backgroundColor: theme.palette.action.disabledBackground,
                            color: theme.palette.text.disabled,
                        },
                    }}
                />
            )}
        </Box>
    );
};

export default TasksGrid;
