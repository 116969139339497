import React, { useState, useEffect } from "react";
import { Typography, Grid } from '@mui/material';

import httpProvider from "../provider/HttpProvider";
import CustomAlert from '../components/CustomAlert';

const Stats = ({ token }) => {
    const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
    const [bookmarkStats, setBookmarkStats] = useState({
        "empty_tags": 0,
        "total": 0,
    });
    const [loadingBookmarkStats, setLoadingBookmarkStats] = useState(true);
    const [tagsStats, setTagsStats] = useState({
        "total": 0,
    });
    const [loadingTagsStats, setLoadingTagsStats] = useState(true);

    useEffect(() => {
        httpProvider
            .get('/api/bookmarks/stats', {
                headers: {
                    'Authorization': token,
                }
            })
            .then((response) => {
                setBookmarkStats(response);
                setLoadingBookmarkStats(false);
            })
            .catch((error) => {
                setAlertInfo({ message: error.message });
            });

        httpProvider
            .get('/api/tags/stats', {
                headers: {
                    'Authorization': token,
                }
            })
            .then((response) => {
                setTagsStats(response);
                setLoadingTagsStats(false);
            })
            .catch((error) => {
                setAlertInfo({ message: error.message });
            });
    }, [token]);

    return (
        <Grid container direction="column" alignItems="left">
            <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />
            {!loadingBookmarkStats && !loadingTagsStats && (
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="button" display="block" gutterBottom>
                            Bookmarks:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'light' }} display="block" gutterBottom>{bookmarkStats.total}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="button" display="block" gutterBottom>
                            Bookmarks without tags:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'light' }} display="block" gutterBottom>{bookmarkStats.empty_tags}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="button" display="block" gutterBottom>
                            Total Tags:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'light' }} display="block" gutterBottom>{tagsStats.total}</Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default Stats;
