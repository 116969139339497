import { FormControl, InputLabel, TextareaAutosize } from '@mui/material';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const StyledTextarea = styled('div')`
  width: 100%;
  margin-bottom: 16px; /* Adjust margin as needed */
`;

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    border-radius: 8px;
    padding: 18px 15px 15px 15px; // Adjust padding as needed
    box-sizing: border-box;
    resize: vertical; /* Allow vertical resizing */

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[500]};
      outline: none;
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    &.Mui-focused {
      border-color: ${blue[500]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  `
);

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => `
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : grey[100]}; /* Adjust background color */
    padding: 4px 8px; /* Adjust padding */
    border-radius: 4px; /* Rounded corners */
    font-size: 0.875rem;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    margin-bottom: 8px; /* Spacing below the label */
  `
);

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledTextareaWithLabel = ({ label, ...props }) => (
  <StyledTextarea>
    <StyledFormControl>
      <StyledInputLabel shrink>{label}</StyledInputLabel>
      <Textarea {...props} />
    </StyledFormControl>
  </StyledTextarea>
);

export default StyledTextareaWithLabel;
