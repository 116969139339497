import React, { useState, useEffect, useCallback } from 'react';
import {
    MobileDatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Abc as AbcIcon, HourglassEmpty as HourglassEmptyIcon } from '@mui/icons-material';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import httpProvider from '../../provider/HttpProvider';
import SelectWithSearch from '../SelectWithSearch';
import ThreeDotsLoader from '../ThreeDotsLoader';

dayjs.extend(utc);
dayjs.extend(timezone);

const initialFormData = {
    title: '',
    description: '',
    priority: 1,
    tags: [],
    dueDate: dayjs(),
    link: '',
    is_completed: false,
};

const priorityOptions = [
    { value: '3', label: 'High' },
    { value: '2', label: 'Medium' },
    { value: '1', label: 'Low' },
];

const TaskForm = ({ onTaskAdd, onTaskUpdate, token, taskItem }) => {
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);
    const [descriptionCorrection, setDescriptionCorrection] = useState('');
    const [descriptionCorrectionLoading, setDescriptionCorrectionLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (taskItem) {
            setFormData({
                id: taskItem.id,
                title: taskItem.title || '',
                description: taskItem.description || '',
                priority: taskItem.priority || 1,
                tags: taskItem.tags ? taskItem.tags.split(',') : [],
                dueDate: taskItem.due_date ? dayjs(taskItem.due_date) : dayjs(),
                link: taskItem.link || '',
                is_completed: taskItem.is_completed || false,
            });
        }
        setLoading(false);
    }, [token, taskItem]);

    const handleInputChange = useCallback((field) => (event) => {
        setFormData((prevState) => ({ ...prevState, [field]: event.target.value }));
    }, []);

    const handleTagSelect = useCallback((value) => {
        setFormData((prevState) => ({ ...prevState, tags: value }));
    }, []);

    const handleUpdate = useCallback(() => {
        setLoading(true);
        if (!isFormValid()) {
            setLoading(false);
            return;
        }
        const newRow = {
            ...formData,
            priority: parseInt(formData.priority, 10),
            tags: formData.tags.join(','),
            due_date: formData.dueDate.toISOString(),
        };
        if (taskItem) {
            onTaskUpdate(newRow);
        } else {
            onTaskAdd(newRow);
            resetForm();
        }
        setLoading(false);
    }, [formData, taskItem, onTaskAdd, onTaskUpdate]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        handleUpdate();
    }, [handleUpdate]);

    const handleDone = useCallback(() => {
        formData.is_completed = true;
        handleUpdate();
    }, [handleUpdate]);

    const handleTodo = useCallback(() => {
        formData.is_completed = false;
        handleUpdate();
    }, [handleUpdate]);

    const isFormValid = useCallback(() => {
        const newErrors = {};
        if (!formData.title) newErrors.title = 'Title is empty';
        if (!formData.description) newErrors.description = 'Description is empty';
        setErrors(newErrors);
        return !Object.keys(newErrors).length;
    }, [formData]);

    const resetForm = useCallback(() => {
        setFormData(initialFormData);
    }, []);

    const onDatePicked = useCallback((date) => {
        if (date) {
            setFormData((prevState) => ({ ...prevState, dueDate: date }));
        }
    }, []);

    const handleSpellingCheck = useCallback(() => {
        if (formData.description.length > 10) {
            setDescriptionCorrectionLoading(true);
            httpProvider.post('/api/openai/spell-check', { content: formData.description }, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'text/plain',
                }
            }).then((response) => {
                setDescriptionDialogOpen(true);
                setDescriptionCorrection(response.content);
                setDescriptionCorrectionLoading(false);
            }).catch((error) => {
                setError(error.message);
                setDescriptionCorrectionLoading(false);
            });
        }
    }, [formData.description, token]);

    const descriptionDialogHandleClose = useCallback(() => {
        setDescriptionDialogOpen(false);
    }, []);

    const descriptionDialogHandleApprove = useCallback(() => {
        setFormData((prevState) => ({ ...prevState, description: descriptionCorrection }));
        setDescriptionDialogOpen(false);
    }, [descriptionCorrection]);

    const SpellingCheckButton = useCallback(() => (
        <Button
            variant="outlined"
            onClick={!descriptionCorrectionLoading ? handleSpellingCheck : null}
            disabled={descriptionCorrectionLoading}
        >
            {descriptionCorrectionLoading ? <HourglassEmptyIcon /> : <AbcIcon />}
        </Button>
    ), [descriptionCorrectionLoading, handleSpellingCheck]);

    return (
        <Grid container>
            <Grid item xs={12}>
                {loading && <ThreeDotsLoader />}
            </Grid>
            {!loading && (
                <Grid item xs={12}>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            {error && <FormHelperText>{error}</FormHelperText>}
                            <Grid item xs={12} md={5}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        id="due-date"
                                        label="Due Date"
                                        value={formData.dueDate}
                                        onChange={onDatePicked}
                                        format='DD/MM/YYYY'
                                        timezone="UTC"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    id="priority"
                                    select
                                    value={formData.priority}
                                    label="Priority"
                                    fullWidth
                                    onChange={handleInputChange('priority')}
                                >
                                    {priorityOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={formData.title}
                                    error={!!errors.title}
                                    helperText={errors.title}
                                    label="Title"
                                    fullWidth
                                    onChange={handleInputChange('title')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={formData.description}
                                    error={!!errors.description}
                                    helperText={errors.description}
                                    label="Description"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    onChange={handleInputChange('description')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SpellingCheckButton />
                            </Grid>
                            <Dialog
                                open={descriptionDialogOpen}
                                onClose={descriptionDialogHandleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Accept the correction?"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {descriptionCorrection}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={descriptionDialogHandleClose}>Dismiss</Button>
                                    <Button onClick={descriptionDialogHandleApprove} autoFocus>Approve</Button>
                                </DialogActions>
                            </Dialog>
                            <Grid item xs={12}>
                                <SelectWithSearch token={token} onSelect={handleTagSelect} value={formData.tags} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={formData.link}
                                    error={!!errors.link}
                                    helperText={errors.link}
                                    placeholder="https://"
                                    fullWidth
                                    onChange={handleInputChange('link')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ mt: 2, mb: 4 }} columnSpacing={2}>
                                    <Grid item >
                                        <Button variant="contained" type="submit">
                                            {taskItem ? "Update Task" : "Add Task"}
                                        </Button>
                                    </Grid>
                                    {taskItem && (
                                        <Grid item >
                                            {!formData.is_completed && <Button variant="contained" onClick={handleDone}>Done</Button>}
                                            {formData.is_completed && <Button variant="outlined" onClick={handleTodo}>Todo</Button>}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default TaskForm;
