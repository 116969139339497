import axios from 'axios';

const httpProvider = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_HOST_PORT}`,
});

httpProvider.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        if (error.response.status === 401) {
            window.location.replace("/logout");
        }
        return Promise.reject(error);
    }
)
export default httpProvider;