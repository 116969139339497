import React from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Area, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomTooltip';

const TemperatureGraph = ({ data }) => {
    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
            <AreaChart
                data={data}
                syncId="anyId">
                <XAxis dataKey="date" />
                <YAxis />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y="0" stroke="#118ab2" label="0°C" />
                <Area name="Temperature (°C)" type="monotone" dataKey="temperature" stroke="#118ab2" fill="#118ab2" unit="°C" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default TemperatureGraph;
