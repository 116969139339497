import React, { useEffect, useState } from 'react';
import {
    AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';
import {
    Container, Typography, Grid, TextField, FormHelperText, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import axios from 'axios';
import 'dayjs/locale/en-gb';
dayjs.locale('en-gb');

const BitcoinPrice = () => {
    const [price, setPrice] = useState(null);
    const [historicalData, setHistoricalData] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().subtract(365, 'days'));
    const [endDate, setEndDate] = useState(dayjs());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [currencyCode, setCurrencyCode] = useState('EUR');

    const currencies = ['PLN', 'USD', 'EUR', 'GBP'];

    const fetchCurrentPrice = async () => {
        const cacheKey = `currentPrice_${currencyCode}`;

        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            const expirationTime = timestamp + 3600000; // 1 hour in milliseconds
            if (Date.now() < expirationTime) {
                setPrice(data);
                setError("");
                return;
            }
        }

        try {
            const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=${currencyCode}`);
            const price = response.data.bitcoin[currencyCode.toLowerCase()];
            setPrice(price);
            setError("");
            localStorage.setItem(cacheKey, JSON.stringify({ data: price, timestamp: Date.now() }));
        } catch (error) {
            setError('Failed to fetch Bitcoin price.');
        }
    };

    const fetchHistoricalData = async (start, end) => {
        const cacheKey = `historicalData_${currencyCode}_${start.unix()}_${end.unix()}`;
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            const expirationTime = timestamp + 3600000; // 1 hour in milliseconds
            if (Date.now() < expirationTime) {
                setHistoricalData(data);
                return;
            }
        }
        try {
            const response = await axios.get(`https://api.coingecko.com/api/v3/coins/bitcoin/market_chart/range`, {
                params: {
                    vs_currency: currencyCode,
                    from: start.unix(),
                    to: end.unix()
                }
            });
            const data = response.data.prices.map(price => ({
                date: dayjs(price[0]).format('YYYY-MM-DD'),
                value: price[1]
            }));
            setHistoricalData(data);
            localStorage.setItem(cacheKey, JSON.stringify({ data, timestamp: Date.now() }));
        } catch (error) {
            setError('Failed to fetch historical data.');
        }
    };

    const handleUpdateClick = () => {
        setLoading(true);
        fetchHistoricalData(startDate, endDate).then(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        fetchCurrentPrice();
        fetchHistoricalData(startDate, endDate).then(() => setLoading(false));
    }, [currencyCode]);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyCode,
        }).format(value);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${formatCurrency(payload[0].value)} | ${label}`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <Container sx={{ marginBottom: 4 }}>
            {price !== null && (
                <Typography variant="h6">
                    <small>Bitcoin:</small> {formatCurrency(price)}
                </Typography>
            )}
            <Grid container rowSpacing={2} sx={{ marginTop: 2 }} alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12} sm={3}>
                        <MobileDatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newDate) => setStartDate(newDate)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <MobileDatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newDate) => setEndDate(newDate)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Select
                            value={currencyCode}
                            label="Currency"
                            onChange={(e) => setCurrencyCode(e.target.value)}
                        >
                            {currencies.map((currency) => (
                                <MenuItem key={currency} value={currency}>
                                    {currency}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <LoadingButton
                            loading={loading}
                            loadingIndicator="Loading…"
                            onClick={handleUpdateClick}
                        >
                            Update Data
                        </LoadingButton>
                    </Grid>
                </LocalizationProvider>
            </Grid>
            <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
                {historicalData.length > 0 && (
                    <AreaChart width={600} height={300} data={historicalData} style={{ marginTop: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis scale="log" domain={[20000, 80000]} />
                        <Tooltip content={<CustomTooltip />} />
                        <Area type="monotone" dataKey="value" stroke="#ffba08" fill="#ffba08" />
                    </AreaChart>
                )}
            </ResponsiveContainer>
            {error && <FormHelperText>{error}</FormHelperText>}
        </Container>
    );
};

export default BitcoinPrice;
