import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { format, subDays } from 'date-fns';

const DateRangeSelector = ({ selectedDate, onDateChange }) => {

    const generateDateOptions = () => {
        const intervals = [1, 3, 7, 30, 90, 360];
        const today = new Date();
        const options = intervals.map(days => {
            const pastDate = subDays(today, days);
            return {
                label: `${days} Days Ago`,
                value: format(pastDate, 'yyyy-MM-dd'),
            };
        });
        return options;
    };

    const dateOptions = generateDateOptions();
    const [intSelectedDate, setIntSelectedDate] = useState(dateOptions[1].value);

    useEffect(() => {
    }, [selectedDate]);

    const handleRangeChange = (event) => {
        setIntSelectedDate(event.target.value)
        onDateChange(event.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="range-select-label">Range</InputLabel>
            <Select
                labelId="range-select-label"
                id="range-select"
                value={intSelectedDate}
                label="Range"
                onChange={handleRangeChange}
            >
                {dateOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DateRangeSelector;
