import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    Grid,
    TextField,
} from '@mui/material';
import {
    LocalizationProvider,
    MobileDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import httpProvider from '../../provider/HttpProvider';

import { StyledTextField, StyledTextarea } from '../../styles';

dayjs.extend(utc);
dayjs.extend(timezone);

const initialFormData = {
    url: '',
    hash: '',
    tags: '',
    title: '',
    author: '',
    length: 0,
    excerpt: '',
    site_name: '',
    image: '',
    favicon: '',
    summary: '',
    source: '',
    markdown: '',
    language: '',
    publish_date: dayjs(),
};

const UpdateBookmarkForm = ({ initialBookmarkData: initialData }) => {
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');

    useEffect(() => {
        if (initialData) {
            setFormData({
                url: initialData.url || '',
                hash: initialData.hash || '',
                tags: initialData.tags || '',
                title: initialData.title || '',
                author: initialData.author || '',
                length: initialData.length || 0,
                excerpt: initialData.excerpt || '',
                site_name: initialData.site_name || '',
                image: initialData.image || '',
                favicon: initialData.favicon || '',
                summary: initialData.summary || '',
                source: initialData.source || '',
                markdown: initialData.markdown || '',
                language: initialData.language || '',
                publish_date: initialData.publish_date ? dayjs(initialData.publish_date) : dayjs(),
            });
        }
        setLoading(false);
    }, [initialData]);

    const handleInputChange = useCallback((field) => (event) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: event.target.value,
        }));
    }, []);

    const isFormValid = useCallback(() => {
        const newErrors = {};
        if (!formData.title) newErrors.title = 'Title is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData]);

    const handleUpdate = useCallback(() => {
        setLoading(true);
        if (!isFormValid()) {
            setLoading(false);
            return;
        }
        const updatedBookmark = {
            ...formData,
            priority: parseInt(formData.priority, 10),
            publish_date: formData.publish_date.toISOString(),
        };
        httpProvider.put(`/api/bookmarks/${initialData.hash}`, updatedBookmark)
            .then(response => {
                console.log('Bookmark updated successfully:', response.data);
                setLoading(false);
            })
            .catch(error => {
                setError('Failed to update bookmark');
                setLoading(false);
            });
    }, [formData, initialData, isFormValid]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        handleUpdate();
    }, [handleUpdate]);

    const handleDateChange = useCallback((date) => {
        setFormData(prevState => ({
            ...prevState,
            publish_date: date,
        }));
    }, []);

    return (
        <Grid container spacing={2}>
            {loading && <p>Loading...</p>}
            {!loading && (
                <Grid item xs={12} rowSpacing={4}>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="URL"
                                    value={formData.url}
                                    onChange={handleInputChange('url')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Tags"
                                    value={formData.tags}
                                    onChange={handleInputChange('tags')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Title"
                                    value={formData.title}
                                    onChange={handleInputChange('title')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Author"
                                    value={formData.author}
                                    onChange={handleInputChange('author')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Length"
                                    type="number"
                                    value={formData.length}
                                    onChange={handleInputChange('length')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Excerpt"
                                    rows={4}
                                    value={formData.excerpt}
                                    onChange={handleInputChange('excerpt')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Site Name"
                                    value={formData.site_name}
                                    onChange={handleInputChange('site_name')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Image"
                                    value={formData.image}
                                    onChange={handleInputChange('image')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Favicon"
                                    value={formData.favicon}
                                    onChange={handleInputChange('favicon')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Summary"
                                    rows={4}
                                    value={formData.summary}
                                    onChange={handleInputChange('summary')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Source"
                                    value={formData.source}
                                    onChange={handleInputChange('source')}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <StyledTextarea
                                    maxRows={100}
                                    value={formData.markdown}
                                    label="Markdown"
                                    aria-label="Markdown"
                                    placeholder="Maximum 4 rows"
                                    onChange={handleInputChange('markdown')}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextField
                                    label="Language"
                                    value={formData.language}
                                    onChange={handleInputChange('language')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="Publish Date"
                                        value={formData.publish_date}
                                        onChange={handleDateChange}
                                        format="DD/MM/YYYY"
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Update Bookmark
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default UpdateBookmarkForm;
