import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import httpProvider from '../provider/HttpProvider';
import CustomAlert from '../components/CustomAlert';

const IoInputField = ({ token }) => {
    const [ioInput, setIoInput] = useState("");
    const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        httpProvider
            .get('/api/io', {
                headers: {
                    'Authorization': token,
                }
            })
            .then((response) => {
                setIoInput(response);
                setLoading(false);
            })
            .catch((error) => {
                setAlertInfo({ message: error.message });
            });
    }, [token]);

    return (
        <Grid container direction="column" alignItems="left" sx={{ marginTop: 2 }}>
            {alertInfo.message && (
                <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />
            )}
            {!loading && <TextField
                label="IO"
                value={ioInput}
                onChange={(e) => setIoInput(e.target.value)}
                multiline
                maxRows={4}
                fullWidth
                disabled={loading}
            />}
        </Grid>
    );
};

export default IoInputField;
