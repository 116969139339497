import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PrivateRoute from './PrivateRoute';

import Home from '../pages/Home'
import Tags from '../pages/Tags'
import Bookmarks from '../pages/Bookmarks'
import Bookmark from '../pages/Bookmark'
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Weather from '../pages/Weather';
import Dash from '../pages/Dash';
import Tasks from '../pages/Tasks'
import Task from '../pages/Task'
import Search from '../pages/Search'

import Layout from "../components/Layout";

const Routes = () => {
  const router = createBrowserRouter([
    {
      Component: Layout,
      children: [
        {
          path: "/dash/*",
          element: <PrivateRoute component={Dash} />,
        },
        {
          path: "/weather/*",
          element: <PrivateRoute component={Weather} />,
        },
        {
          path: "/bookmarks/*",
          element: <PrivateRoute component={Bookmarks} />,
        },
        {
          path: "/bookmarks/:hash",
          element: <PrivateRoute component={Bookmark} />,
        },
        {
          path: "/tags/*",
          element: <PrivateRoute component={Tags} />,
        },
        {
          path: "/tasks/*",
          element: <PrivateRoute component={Tasks} />,
        },
        {
          path: "/tasks/:id",
          element: <PrivateRoute component={Task} />,
        },
        {
          path: "/search/:term",
          element: <PrivateRoute component={Search} />,
        },
        {
          path: "/search/*",
          element: <PrivateRoute component={Search} />,
        },
      ],
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login/*",
      element: <Login />,
    },
    {
      path: "/logout/*",
      element: <PrivateRoute component={Logout} />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
