import React, { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import FlareIcon from '@mui/icons-material/Flare';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AirIcon from '@mui/icons-material/Air';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import Box from '@mui/material/Box';
import Breadcrumb from '../components/Breadcrumb';
import ThreeDotsLoader from "../components/ThreeDotsLoader";

import DateRangeSelector from "../components/DateRangeSelector";
import CustomAlert from '../components/CustomAlert';
import SensorTable from "../components/tables/SensorsTable";
import TemperatureGraph from "../components/graphs/TemperatureGraph";
import PressureGraph from "../components/graphs/PressureGraph";
import HeatIndexGraph from "../components/graphs/HeatIndexGraph";
import AirHumidityGraph from "../components/graphs/AirHumidityGraph";
import WindGraph from "../components/graphs/WindGraph";
import LightGraph from "../components/graphs/LightGraph";
import DeviceTemperatureGraph from "../components/graphs/DeviceTemperatureGraph";
import MoistureGraph from "../components/graphs/MoistureGraph";
import HallGraph from "../components/graphs/HallGraph";
import WeatherHeroGraph from "../components/graphs/WeatherHeroGraph";

const Weather = () => {

  const breadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'Weather' }
  ];

  const DEFAULT_PER_PAGE = 1000000
  const urlParams = new URLSearchParams(window.location.search);

  const [mainChart, setMainChart] = useState({});
  const [mainChartStats, setMainChartStats] = useState({});
  const [loadingWeather, setLoadingWeather] = useState(true);
  const [rowsPerPage,] = useState(urlParams.get('size') ? parseInt(urlParams.get('size')) : DEFAULT_PER_PAGE);
  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(today.getDate() - 3);
    return threeDaysAgo;
  });

  const formatDate = (date) => {
    let language = navigator.language || navigator.userLanguage;
    return (new Date(date)).toLocaleString(language);
  };

  const formatIntToPercentage = (v, max = 4095) => {
    return (parseInt(v) / max) * 100;
  }

  const formatPressure = (v) => {
    return (v * 0.01).toPrecision(5);
  }

  const formatWindSpeed = (v) => {
    return (v * 15).toPrecision(5);
  }

  const CalculateTrend = (sensorData, maxReadings = 5) => {
    if (sensorData.length < maxReadings) {
      return null;
    }

    const lastReadings = sensorData.slice(0, maxReadings);
    const latestReading = sensorData[0];

    const trend = {
      temperature: parseFloat(latestReading.temperature) - parseFloat(lastReadings[maxReadings - 1].temperature),
      humidity: parseFloat(latestReading.humidity) - parseFloat(lastReadings[maxReadings - 1].humidity),
      light: parseInt(latestReading.light) - parseInt(lastReadings[maxReadings - 1].light),
      pressure: parseFloat(latestReading.pressure) - parseFloat(lastReadings[maxReadings - 1].pressure),
      device_temperature: parseFloat(latestReading.device_temperature) - parseFloat(lastReadings[maxReadings - 1].device_temperature),
      wind_speed: parseFloat(latestReading.wind_speed) - parseFloat(lastReadings[maxReadings - 1].wind_speed),
    };

    Object.keys(trend).forEach((key) => {
      trend[key] = trend[key] > 0 ? 1 : trend[key] < 0 ? -1 : 0;
    });

    return trend;
  };

  const RenderTrendIcon = (trendValue) => {
    if (trendValue === 1) {
      return <TrendingUpIcon style={{ color: 'green' }} />;
    } else if (trendValue === -1) {
      return <TrendingDownIcon style={{ color: 'red' }} />;
    } else {
      return <TrendingFlatIcon style={{ color: 'grey' }} />;
    }
  };

  const formatISODate = (date) => {
    let isoString = date.toISOString();
    isoString = isoString.replace(/\.\d+Z/, '');
    return isoString;
  };

  const handleSubmit = (rowsPerPage, selectedDate) => {
    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const startDate = new Date(selectedDate);

    endDate.setHours(23, 59, 59, 999);
    let apiUrl = `${process.env.REACT_APP_SENSORS_API}?limit=${rowsPerPage}`;

    if (selectedDate) {
      apiUrl += `&start_date=${formatISODate(startDate)}&end_date=${formatISODate(endDate)}`;
    }

    fetch(apiUrl, {
      headers: {
        'Authorization': `${process.env.REACT_APP_SENSORS_API_TOKEN}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (!data.data || !data.data.rows || data.data.rows.length === 0) {
          setAlertInfo({ message: 'No data available for the selected date range', severity: 'info' });
          setLoadingWeather(false);
          return;
        }
        let response = data.data.rows
        let sensorData = response.map(item => ({
          date: formatDate(item.publish_date),

          moisture: formatIntToPercentage(parseInt(item.moisture)),
          hall: parseInt(item.hall),
          light: parseInt(item.light),
          pressure: formatPressure(parseFloat(item.pressure)),
          altitude: parseFloat(item.altitude),
          wind_speed: formatWindSpeed(item.wind_speed),
          humidity: parseFloat(item.humidity),
          temperature: parseFloat(item.temperature),
          heat_index: parseFloat(item.heat_index),

          device_local_ip: item.device_local_ip,
          device_name: item.device_name,
          device_rssi: item.device_rssi,
          device_version: item.device_version,
          device_temperature: parseFloat(item.device_temperature),
        }))
        console.log(sensorData);
        setMainChart(sensorData)

        setMainChartStats({
          last: {
            temperature: parseFloat(sensorData[0].temperature),
            humidity: parseFloat(sensorData[0].humidity),
            light: parseFloat(sensorData[0].light),
            pressure: parseFloat(sensorData[0].pressure),
            device_temperature: parseFloat(sensorData[0].device_temperature),
            wind_speed: parseFloat(sensorData[0].wind_speed),
          },
          max: {
            temperature: Math.max(...sensorData.map(item => parseFloat(item.temperature))),
            humidity: Math.max(...sensorData.map(item => parseFloat(item.humidity))),
            light: Math.max(...sensorData.map(item => parseFloat(item.light))),
            pressure: Math.max(...sensorData.map(item => parseFloat(item.pressure))),
            device_temperature: Math.max(...sensorData.map(item => parseFloat(item.device_temperature))),
            wind_speed: Math.max(...sensorData.map(item => parseFloat(item.wind_speed))),
          },
          min: {
            temperature: Math.min(...sensorData.map(item => parseFloat(item.temperature))),
            humidity: Math.min(...sensorData.map(item => parseFloat(item.humidity))),
            light: Math.min(...sensorData.map(item => parseFloat(item.light))),
            pressure: Math.min(...sensorData.map(item => parseFloat(item.pressure))),
            device_temperature: Math.min(...sensorData.map(item => parseFloat(item.device_temperature))),
            wind_speed: Math.min(...sensorData.map(item => parseFloat(item.wind_speed))),
          },
          trend: CalculateTrend(sensorData),
          last_reading: sensorData[0].date
        });

        setLoadingWeather(false);

      })
      .catch(error => {
        setAlertInfo({ message: error.message });
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleSubmit(rowsPerPage, date);
  };

  useEffect(() => {
    setLoadingWeather(true);
    const intervalId = setInterval(() => {
      handleSubmit(rowsPerPage, selectedDate);
    }, 180000);
    handleSubmit(rowsPerPage, selectedDate);
    return () => clearInterval(intervalId);
  }, [rowsPerPage]);

  return (
    <Grid container direction="row" sx={{ margin: 1 }} >

      <Breadcrumb breadcrumbs={breadcrumbs} />

      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

      <Grid container direction="row" justifyContent="flex-end" rowSpacing={0}>

        {loadingWeather && <ThreeDotsLoader />}

        {!loadingWeather && <Grid container item xs={12} direction="row" rowSpacing={5}>
          <Grid item xs={6} md={2}>
            <Stack direction="row" gap={1}>
              <ThermostatIcon />
              <Typography variant="body1">Temperature</Typography>
            </Stack>
            <h2>{mainChartStats.last.temperature}°C {mainChartStats.trend && RenderTrendIcon(mainChartStats.trend.temperature)} </h2>
            <small>{mainChartStats.max.temperature}°C / {mainChartStats.min.temperature}°C</small>
          </Grid>
          <Grid item xs={6} md={2}>
            <Stack direction="row" gap={1}>
              <WaterDropIcon />
              <Typography variant="body1">Humidity</Typography>
            </Stack>
            <h2>{mainChartStats.last.humidity} RH {mainChartStats.trend && RenderTrendIcon(mainChartStats.trend.humidity)}</h2>
            <small>{mainChartStats.max.humidity} RH / {mainChartStats.min.humidity} RH</small>
          </Grid>
          <Grid item xs={6} md={2}>
            <Stack direction="row" gap={1}>
              <FlareIcon />
              <Typography variant="body1">Light</Typography>
            </Stack>
            <h2>{mainChartStats.last.light} % {mainChartStats.trend && RenderTrendIcon(mainChartStats.trend.light)}</h2>
            <small>{mainChartStats.max.light} % / {mainChartStats.min.light} %</small>
          </Grid>
          <Grid item xs={6} md={2}>
            <Stack direction="row" gap={1}>
              <LineWeightIcon />
              <Typography variant="body1">Pressure</Typography>
            </Stack>
            <h2>{mainChartStats.last.pressure} hPa {mainChartStats.trend && RenderTrendIcon(mainChartStats.trend.pressure)}</h2>
            <small>{mainChartStats.max.pressure} hPa / {mainChartStats.min.pressure} hPa</small>
          </Grid>
          <Grid item xs={6} md={2}>
            <Stack direction="row" gap={1}>
              <AirIcon />
              <Typography variant="body1">Wind</Typography>
            </Stack>
            <h2>{mainChartStats.last.wind_speed} m/s {mainChartStats.trend && RenderTrendIcon(mainChartStats.trend.wind_speed)}</h2>
            <small>{mainChartStats.max.wind_speed} / {mainChartStats.min.wind_speed} m/s</small>
          </Grid>
          <Grid item xs={10} md={2}>
            <Box>
              <DateRangeSelector onDateChange={handleDateChange} />
            </Box>
            <Grid item xs={12} sx={{ marginTop: 1 }}><small>Last reading: {mainChartStats.last_reading}</small></Grid>

          </Grid>
        </Grid>}

        {!loadingWeather && <Grid container item xs={12}>
          <Grid item xs={12} sx={{ marginTop: 10, marginBottom: 10 }}><WeatherHeroGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><TemperatureGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><PressureGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><HeatIndexGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><AirHumidityGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><WindGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><LightGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><DeviceTemperatureGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><MoistureGraph data={mainChart} /></Grid>
          <Grid item xs={12} md={6}><HallGraph data={mainChart} /></Grid>
          <Grid item xs={12}><SensorTable mainChart={mainChart} loadingWeather={loadingWeather} /></Grid>
        </Grid>}

      </Grid >
    </Grid >
  )
}

export default Weather
