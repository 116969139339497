import React, { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';

import StyleIcon from '@mui/icons-material/Style';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';

const MENU_DELETE = "Delete"
const MENU_VISIT = "Visit"

const ListItem = styled('span')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const SmallBookmark = ({ item, onDelete, onChipClick }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChipClick = (event) => {
    onChipClick(event)
  };

  const handleClose = (option, item) => {
    switch (option) {
      case MENU_DELETE:
        const result = window.confirm("Do you want to continue?");
        if (result) {
          onDelete(item)
        }
        break;
      case MENU_VISIT:
        window.open(item.url, '_blank');
        break;
      default:
        console.log('default', option);
    }

    setAnchorEl(null);
  };

  const options = [
    MENU_VISIT,
    MENU_DELETE
  ];

  const styles = {
    excerpt: {
      whiteSpace: 'normal', // Allows the text to wrap to the next line
      wordBreak: 'break-word', // Breaks long words to prevent overflow
    }
  };

  return (
    <Grid item md={4}>
      <Card variant="outlined">
        <CardHeader
          style={styles.excerpt}
          action={
            <Grid>
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {options.map((option) => (
                    <MenuItem key={option} onClick={() => handleClose(option, item)}>
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>
          }
          title={item.title}
          subheader={item.author}
        />
        <Link to={`/bookmarks/${item.hash}`}>
          <CardMedia
            component="img"
            alt={item.title}
            height="140"
            image={item.image}
          />
        </Link>
        <CardContent>
          <Typography variant="body2" style={styles.excerpt}>
            {item.excerpt}
          </Typography>
        </CardContent>
        <CardActions>

          <Grid item sm={12}>

            <StyleIcon />
            {item.tags.split(',').map(item => item.trim()).map((value, index) => (
              <ListItem key={index}>
                <Chip
                  key={index}
                  label={value}
                  variant="outlined"
                  sx={{
                    marginBottom: 2,
                    marginLeft: 1,
                  }}
                  onClick={() => handleChipClick(value)}
                />
              </ListItem>
            ))}
          </Grid>

        </CardActions>

      </Card>
    </Grid>
  );
};

export default SmallBookmark;