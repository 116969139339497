import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';

import httpProvider from "../provider/HttpProvider";
import Breadcrumb from '../components/Breadcrumb';
import CustomAlert from '../components/CustomAlert';
import TagList from '../components/TagList';
import TagForm from '../components/forms/TagForm';

const Tags = () => {
  const breadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'Tags' }
  ];

  const [pageItems, setPageItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token] = useState(localStorage.getItem("token"))

  const defaultPerPage = 100
  const defaultPage = 1

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const [currentPage, setCurrentPage] = useState(
    urlParams.get('page')
      ? parseInt(urlParams.get('page'))
      : defaultPage
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    urlParams.get('size')
      ? parseInt(urlParams.get('size'))
      : defaultPerPage
  );

  const [pagination, setPagination] = useState({
    "count": 0,
    "rowsPerPage": rowsPerPage,
    "page": currentPage
  });

  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });

  const handleChangePage = (_event, newPage) => {
    setCurrentPage(newPage);
    navigate(`/tags?page=${currentPage}&limit=${rowsPerPage}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleRemoveItem = (item) => {
    setLoading(true);
    const result = window.confirm("Do you want to continue?");
    if (!result) {
      setLoading(false);
      return
    }

    httpProvider.delete(`/api/tags/${item.id}`)
      .then((response) => {
        const updatedDataItems = pageItems[0].items.filter((dataItem) => dataItem.id !== item.id);
        setPageItems([{
          "items": updatedDataItems
        }]);
        setPagination({
          "count": pagination.count - 1,
          "rowsPerPage": pagination.rowsPerPage,
          "page": pagination.page
        })

        setLoading(false);
      })
      .catch((error) => {
        setAlertInfo({ message: error.message });
      });
  };

  const handleClick = (tag) => {
    navigate(`/bookmarks/?page=0&limit=50&search=${tag}`);
  };

  const handleTagAdded = (newTag) => {
    setPageItems((prevPageItems) => {
      const updatedItems = [newTag, ...prevPageItems[0].items];
      return [{ items: updatedItems }];
    });
    setPagination((prev) => ({
      ...prev,
      "count": prev.count + 1
    }));
  };

  useEffect(() => {
    httpProvider
      .get(`/api/tags/?limit=${rowsPerPage}&page=${currentPage}&sort=id&sortDesc=true`, {
        headers: {
          'Authorization': token,
        }
      })
      .then((response) => {
        setPageItems([{
          "items": response.data.rows
        }]);
        setPagination({
          "count": response.data.total_rows,
          "rowsPerPage": response.data.limit,
          "page": response.data.page
        })
        setLoading(false);
      })
      .catch((error) => {
        setAlertInfo({ message: error.message });
      });
  }, [currentPage, rowsPerPage, token]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ margin: 1 }} >

      <Breadcrumb breadcrumbs={breadcrumbs} />

      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

      <Grid item xs={12}>
        <TagForm onTagAdded={handleTagAdded} onTagError={setAlertInfo} />
      </Grid>

      <Grid item xs={12}>
        {!loading && pageItems.length > 0 &&
          pageItems.map((list, index) => {
            return (
              <section key={index}>

                {list.items.length > 0 && <TablePagination
                  component="div"
                  count={pagination.count}
                  page={pagination.page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />}

                <TagList
                  items={list.items}
                  onTagClick={handleClick}
                  onTagDelete={handleRemoveItem}
                />

                {list.items.length > 0 && <TablePagination
                  component="div"
                  count={pagination.count}
                  page={pagination.page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pagination.rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />}
              </section>
            );
          })}
      </Grid>

    </Grid>
  );
}

export default Tags;
