import React from 'react';
import { Grid, Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap',
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const SensorTable = ({ mainChart, loadingWeather }) => {
    return (
        !loadingWeather && (
            <Grid item xs={12} sx={{ marginTop: 10 }}>
                <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                    <Table stickyHeader size="small" aria-label="sensor readings">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell align="right">Moisture (%)</StyledTableCell>
                                <StyledTableCell align="right">Hall (%)</StyledTableCell>
                                <StyledTableCell align="right">Light</StyledTableCell>
                                <StyledTableCell align="right">Pressure (hPa)</StyledTableCell>
                                <StyledTableCell align="right">Altitude (m)</StyledTableCell>
                                <StyledTableCell align="right">Wind Speed (m/s)</StyledTableCell>
                                <StyledTableCell align="right">Humidity (%)</StyledTableCell>
                                <StyledTableCell align="right">Temperature (°C)</StyledTableCell>
                                <StyledTableCell align="right">Heat Index (°C)</StyledTableCell>
                                <StyledTableCell align="right">Device Local IP</StyledTableCell>
                                <StyledTableCell align="right">Device Name</StyledTableCell>
                                <StyledTableCell align="right">Device RSSI</StyledTableCell>
                                <StyledTableCell align="right">Device Version</StyledTableCell>
                                <StyledTableCell align="right">Device Temperature (°C)</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {mainChart.map((row) => (
                                <StyledTableRow
                                    key={row.date}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell component="th" scope="row">{row.date}</StyledTableCell>
                                    <StyledTableCell align="right">{parseInt(row.moisture)}</StyledTableCell>
                                    <StyledTableCell align="right">{row.hall}</StyledTableCell>
                                    <StyledTableCell align="right">{parseInt(row.light)}</StyledTableCell>
                                    <StyledTableCell align="right">{row.pressure}</StyledTableCell>
                                    <StyledTableCell align="right">{row.altitude}</StyledTableCell>
                                    <StyledTableCell align="right">{isNaN(row.wind_speed) ? 'N/A' : parseInt(row.wind_speed)}</StyledTableCell>
                                    <StyledTableCell align="right">{row.humidity}</StyledTableCell>
                                    <StyledTableCell align="right">{row.temperature}</StyledTableCell>
                                    <StyledTableCell align="right">{parseInt(row.heat_index)}</StyledTableCell>
                                    <StyledTableCell align="right">{row.device_local_ip}</StyledTableCell>
                                    <StyledTableCell align="right">{row.device_name}</StyledTableCell>
                                    <StyledTableCell align="right">{row.device_rssi}</StyledTableCell>
                                    <StyledTableCell align="right">{row.device_version}</StyledTableCell>
                                    <StyledTableCell align="right">{row.device_temperature}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    );
}

export default SensorTable;
