import React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';

const TagList = ({ items, onTagClick, onTagDelete }) => {
    return (
        <Grid container spacing={1} sx={{ marginTop: 2, marginBottom: 2 }}>
            {items.map((value, index) => (
                <Grid item key={index}>
                    <Chip
                        label={value.name}
                        variant="outlined"
                        onClick={() => onTagClick(value.name)}
                        onDelete={() => onTagDelete(value)}
                        deleteIcon={<DeleteIcon />}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default TagList;
