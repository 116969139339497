import React from 'react';
import { useAuth } from '../provider/AuthProvider';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useAuth();
  return (
    token ? <Component /> : <Navigate to="/login" />
  );
};

export default PrivateRoute;
