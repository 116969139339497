import React from 'react';
import Menu from './MainMenu';
import Grid from '@mui/material/Grid';

import { Outlet } from "react-router-dom";

const Layout = () => {
    return (
        <Grid container justifyContent="flex-start">
            <Menu />
            <Outlet />
        </Grid>
    );
}

export default Layout;
