import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Breadcrumb from '../components/Breadcrumb';
import CustomAlert from '../components/CustomAlert';
import TaskForm from '../components/forms/TasksForm';
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import httpProvider from '../provider/HttpProvider';
import AddBookmarkForm from "../components/forms/AddBookmarkForm";
import BookmarksGrid from "../components/BookmarksGrid";
import TasksGrid from "../components/TasksGrid";
import { useAuth } from '../provider/AuthProvider';

const Task = () => {
  const breadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'Tasks', href: '/tasks' },
    { label: 'Task' }
  ];

  const [loading, setLoading] = useState(true);

  const { token } = useAuth();

  const [pageItem, setPageItem] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });

  const { id } = useParams();

  const navigate = useNavigate();

  const formatDate = (date) => {
    let language = navigator.language || navigator.userLanguage;
    return (new Date(date)).toLocaleString(language);
  };

  const fetchItemsByTag = (data) => {
    setLoading(true);

    const tag = data.tags
    const tasksUrl = `/api/tasks/?tag=${tag}&sort=id&sortDesc=true`;
    const bookmarksUrl = `/api/bookmarks/search?limit=5&tag=${tag}`;

    const tasksPromise = httpProvider.get(tasksUrl, { headers: { 'Authorization': token } });
    const bookmarksPromise = httpProvider.get(bookmarksUrl, { headers: { 'Authorization': token } });

    Promise.all([tasksPromise, bookmarksPromise])
      .then((responses) => {
        console.log(responses);
        setTasks(responses[0].data.rows.filter(row => row.id !== data.id) || []);
        setBookmarks(responses[1].data.rows || []);
        setLoading(false);
      })
      .catch((error) => {
        setAlertInfo({ message: error.message });
        setLoading(false);
      });
  };

  const fetchTask = (id) => {
    setLoading(true);
    httpProvider.get(`/api/tasks/${id}`, {
      headers: {
        'Authorization': token,
      }
    })
      .then((response) => {
        setPageItem(response.data || []);
        fetchItemsByTag(response.data)
        setLoading(false);
      })
      .catch((error) => {
        setAlertInfo({ message: error.message });
        setLoading(false);
      });
  };

  const handleUpdateTask = (row) => {
    setLoading(true);
    httpProvider.put(`/api/tasks/${row.id}`, row)
      .then((response) => {
        setPageItem(response.data);
        setLoading(false);
        setAlertInfo({ message: 'Task updated', severity: 'success' });
        navigate(0);
      })
      .catch((error) => {
        setAlertInfo({ message: error.message });
        setLoading(false);
      });
  };

  const handleAddSuccess = () => {
    setLoading(true);
    window.location.reload(false);
  };

  const handleAddError = (message) => {
    setLoading(false);
    setAlertInfo({ message, severity: 'warning' });
  };

  useEffect(() => {
    setLoading(false);
    fetchTask(id);
  }, [id, token]);

  return (
    <Grid container sx={{ margin: 1 }} columnSpacing={2}>

      <Breadcrumb breadcrumbs={breadcrumbs} />

      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

      <Grid item xs={12} md={6}>

        <TaskForm onTaskUpdate={handleUpdateTask} token={token} taskItem={pageItem} />

        <Grid item xs={12} sx={{ marginLeft: -2, marginTop: 2 }}>
          <List dense>
            <ListItem><small>Due Date: {formatDate(pageItem.due_date)}</small></ListItem>
            <ListItem><small>Created At: {formatDate(pageItem.created_at)}</small></ListItem>
            <ListItem><small>Updated At: {formatDate(pageItem.updated_at)}</small></ListItem>
          </List>
        </Grid>

      </Grid>

      <Grid item xs={12} md={6}>

        <Grid item xs={12}>
          <h3>Add Bookmark</h3>
          <AddBookmarkForm token={token} onAddSuccess={handleAddSuccess} onAddError={handleAddError} />
        </Grid>

        <Grid item xs={12}>
          <h3>Related Tasks</h3>
          {!loading && <TasksGrid tasks={tasks} loading={loading} />}
        </Grid>

        <Grid item xs={12}>
          <h3>Related Bookmarks</h3>
          {!loading && <BookmarksGrid bookmarks={bookmarks} loading={loading} />}
        </Grid>

      </Grid>

      <Grid item xs={12} md={6}>
        {loading &&
          <Grid container justifyContent="center">
            <ThreeDotsLoader />
          </Grid>
        }
      </Grid>

    </Grid>
  );
}

export default Task;
