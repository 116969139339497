import React, { useState, useEffect } from 'react';
import { format } from 'date-fns'; // Use date-fns for date formatting

const Clock = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formattedDate = format(time, 'EEEE, MMMM d, yyyy'); // Day of the week, Month Day, Year
    const formattedTime = format(time, 'h:mm:ss a'); // 12-hour clock with AM/PM

    return (
        <div style={styles.container}>
            <div style={styles.time}>{formattedTime}</div>
            <div style={styles.date}>{formattedDate}</div>
        </div>
    );
};

const styles = {
    container: {
        position: 'fixed',
        bottom: '100px',
        right: '20%',
        left: '20%',
        width: '60%',
        padding: '1em',
        textAlign: 'center',
        color: '#333',
        zIndex: 2,
        fontFamily: '"Benton Sans Bk", sans-serif',
    },
    time: {
        fontSize: '2em',
        fontWeight: 'bold',
        lineHeight: '1em',
        margin: '0',
        color: '#fff',
        textShadow: '0px 8px 15px rgba(0, 0, 0, 0.6)',
        whiteSpace: 'nowrap',
    },
    date: {
        fontSize: '.6em',
        fontWeight: '300',
        lineHeight: '1em',
        marginTop: '5px',
        marginLeft: '2em',
        color: '#fff',
        textShadow: '0px 8px 15px rgba(0, 0, 0, 0.6)',
        display: 'block',
        margin: '0 auto'
    }
};



export default Clock;
