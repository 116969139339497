import React, { useState, useEffect } from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { keyframes } from '@emotion/react';
import { Typography } from "@mui/material";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import CustomAlert from '../components/CustomAlert';
import Video from '../components/Video';

const Home = () => {

  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
  const [loading, setLoading] = useState(true);
  const [visitorId, setVisitorId] = useState('');

  const slideInFromBottomLeft = keyframes`
  0% {
    transform: translateX(-100%) translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
`;

  const slideInFromTopRight = keyframes`
  0% {
    transform: translateX(100%) translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
`;

  const slideInFromTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

  useEffect(() => {

    const fpPromise = FingerprintJS.load();
    fpPromise
      .then(fp => fp.get())
      .then(result => {
        const visitorId = result.visitorId;
        setVisitorId(visitorId);
        console.log(visitorId);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error loading fingerprint:', error);
      });

  }, []);

  return (
    <Grid container justifyContent="flex-start">
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ margin: 1 }} >
        <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />
        <Video />

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            padding: '8px 16px',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            animation: `${slideInFromTop} 0.5s ease-out`,
          }}
        >
          <Link
            href="/login"
            color="inherit"
            variant="body2"
            sx={{
              fontSize: '0.75rem',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
          >
            Login
          </Link>
        </Box>

        {!loading && <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            margin: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            borderRadius: 1,
            padding: '8px 16px',
            animation: `${slideInFromBottomLeft} 0.5s ease-out`
          }}
        >
          <Typography variant="body1"><small>{visitorId}</small></Typography>
        </Box>}

      </Grid>
    </Grid >
  )
}

export default Home
