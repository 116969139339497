// BookmarksList.js
import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

const BookmarksGrid = ({ bookmarks, loading }) => {
    const bookmarksColumns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 450,
            editable: true,
        },
        {
            field: 'hash',
            headerName: 'Link',
            width: 150,
            renderCell: (params) => (
                <a href={'/bookmarks/' + params.value} target="_blank" rel="noopener noreferrer">
                    {params.value}
                </a>
            ),
        },
        {
            field: 'tags',
            headerName: 'Tags',
            width: 400,
            editable: true,
        },
        {
            field: 'excerpt',
            headerName: 'Excerpt',
            width: 650,
            editable: true,
        }
    ];

    const handleSelectionChange = (newSelection) => {
        console.log('Selected IDs:', newSelection);
    };

    return (
        <Box>
            <small>Total Found: {bookmarks.length}</small>
            {bookmarks.length > 0 && <DataGrid
                rows={bookmarks}
                columns={bookmarksColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                density={`compact`}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={handleSelectionChange}
            />}
        </Box>
    );
};

export default BookmarksGrid;
