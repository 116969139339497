import React from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Area, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomTooltip';

const MoistureGraph = ({ data }) => {
    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
            <AreaChart
                data={data}
                syncId="anyId">
                <XAxis dataKey="date" />
                <YAxis scale="log" domain={[30, 100]} />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y="40" stroke="#ffba08" label="Wet" />
                <ReferenceLine y="65" stroke="#ffba08" label="Dry" />
                <Area name="Moisture (%)" type="monotone" dataKey="moisture" stroke="#006d77" fill="#006d77" unit="%" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default MoistureGraph;
