import React, { useEffect, useState } from 'react';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Container, Typography, Grid, TextField, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';

const CACHE_DURATION = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

const MetalExchangeRate = ({ token, currencyCode, currencies }) => {
    const [goldPrices, setGoldPrices] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().subtract(5, 'days'));
    const [endDate, setEndDate] = useState(dayjs());
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [latestRate, setLatestRate] = useState(null);

    const fetchWithCache = async (url, cacheKey) => {
        const cached = localStorage.getItem(cacheKey);
        if (cached) {
            const { data, timestamp } = JSON.parse(cached);
            if (Date.now() - timestamp < CACHE_DURATION) {
                return data;
            }
        }

        const response = await fetch(url);
        const data = await response.json();
        if (data.success) {
            localStorage.setItem(cacheKey, JSON.stringify({ data, timestamp: Date.now() }));
        }
        return data;
    };

    const fetchGoldPrices = async (start, end) => {
        const startDateString = start.format('YYYY-MM-DD');
        const endDateString = end.format('YYYY-MM-DD');
        const apiKey = token;
        const url = `https://api.metalpriceapi.com/v1/timeframe?start_date=${startDateString}&end_date=${endDateString}&api_key=${apiKey}&base=${currencyCode}&currencies=${currencies}`;
        const cacheKey = `goldPrices_${currencyCode}_${startDateString}_${endDateString}_${currencies}`;

        setLoading(true);

        try {
            const data = await fetchWithCache(url, cacheKey);
            if (data.success) {
                const rates = Object.keys(data.rates).map(date => ({
                    date,
                    value: formatValue(data.rates[date][currencies]),
                }));
                setLatestRate(rates[rates.length - 1].value);
                setGoldPrices(rates);
            } else {
                setError('Error: No rates data in response');
            }
        } catch (error) {
            console.error('Error fetching gold prices:', error);
            setError('Error fetching gold prices');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateClick = () => {
        fetchGoldPrices(startDate, endDate);
    };

    const formatValue = (value) => {
        return 1 / ((value * 1000).toFixed(8) / 28.34952);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${formatValue(payload[0].value)} | ${label}`}</p>
                </div>
            );
        }
        return null;
    };

    useEffect(() => {
        fetchGoldPrices(startDate, endDate);
    }, []);

    return (
        <Container sx={{ marginBottom: 4 }}>
            {latestRate !== null && (
                <Typography variant="h6">
                    Latest {currencyCode} Rate: {latestRate.toFixed(2)} 1g/{currencies}
                </Typography>
            )}
            <Grid container rowSpacing={2} sx={{ marginTop: 2 }} alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12} sm={3}>
                        <MobileDatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newDate) => setStartDate(newDate)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <MobileDatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newDate) => setEndDate(newDate)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <LoadingButton
                            loading={loading}
                            loadingIndicator="Loading…"
                            onClick={handleUpdateClick}>
                            Update Data
                        </LoadingButton>
                    </Grid>
                </LocalizationProvider>
            </Grid>
            <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
                {goldPrices.length > 0 && (
                    <AreaChart width={600} height={300} data={goldPrices} style={{ marginTop: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                    </AreaChart>
                )}
            </ResponsiveContainer>
            {error && <FormHelperText>{error}</FormHelperText>}
        </Container>
    );
};

export default MetalExchangeRate;
