import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
  FormHelperText,
  Checkbox,
  ListItemText
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import httpProvider from '../provider/HttpProvider';

const containsText = (text, searchText) => text.toLowerCase().includes(searchText.toLowerCase());

const SelectWithSearch = ({ token, onSelect, value }) => {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const handleChange = useCallback((event) => {
    const selected = event.target.value;
    setSelectedOptions(selected);
    onSelect(selected);
  }, [onSelect]);

  const handleErrors = useCallback(() => {
    if (!loading && availableOptions.length === 0) {
      setError("No tags available");
    } else {
      setError("");
    }
  }, [loading, availableOptions.length]);

  useEffect(handleErrors, [availableOptions, searchText]);

  const displayedOptions = useMemo(
    () => availableOptions.filter(option => containsText(option.name, searchText)),
    [availableOptions, searchText]
  );

  useEffect(() => {
    const fetchTags = () => {
      httpProvider
        .get(`/api/tags/?limit=1000&sort=id&sortDesc=true`, {
          headers: {
            'Authorization': token,
          }
        })
        .then((response) => {
          setAvailableOptions(response.data.rows);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
        });
    };
    fetchTags();
  }, [token]);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const getSelectedOptionNames = () => {
    if (!Array.isArray(selectedOptions)) return '';
    return selectedOptions
      .map(slug => availableOptions.find(option => option.slug === slug)?.name)
      .filter(Boolean)
      .join(', ');
  };

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel id="search-select-label">Options</InputLabel>
      <Select
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        multiple
        value={selectedOptions}
        label="Options"
        onChange={handleChange}
        onClose={() => setSearchText("")}
        renderValue={getSelectedOptionNames}
      >
        <ListSubheader>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.map((option) => (
          <MenuItem key={option.id} value={option.slug}>
            <Checkbox checked={selectedOptions.indexOf(option.slug) > -1} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectWithSearch;
