import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Audio as AudioLoader } from 'react-loader-spinner';
import {
  CardMedia,
  Button,
  Typography,
  Grid,
  Chip,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import Markdown from 'react-markdown';
import axios from 'axios';
import httpProvider from "../provider/HttpProvider";
import CustomAlert from '../components/CustomAlert';
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import Breadcrumb from '../components/Breadcrumb';
import UpdateBookmarkForm from "../components/forms/BookmarksForm";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Bookmark = () => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const token = useMemo(() => localStorage.getItem("token"), []);

  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [playAudio, setPlayAudio] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState(0);
  const [reloadFlag, setReloadFlag] = useState(false); // use a flag to reload data

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumbs = useMemo(() => [
    { label: 'Home', href: '/' },
    { label: 'Bookmarks', href: '/bookmarks' },
    { label: 'Bookmark' }
  ], []);

  const options = useMemo(() => ['Delete', 'Read Aloud'], []);

  const fetchBookmark = useCallback(() => {
    setLoading(true);
    httpProvider.get(`/api/bookmarks/${hash}`, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        setItem(response.data);
      })
      .catch((error) => {
        setAlertInfo({ message: error.message, severity: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [hash, token]);

  const handleUpdateBookmark = async (updatedBookmark) => {
    try {
      setLoading(true);
      await httpProvider.put(`/api/bookmarks/${updatedBookmark.hash}`, updatedBookmark);
      setAlertInfo({ message: 'Bookmark updated successfully', severity: 'success' });
      setReloadFlag(prev => !prev); // toggle the reload flag to trigger re-fetch
    } catch (error) {
      setAlertInfo({ message: 'Failed to update bookmark', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = async (option, hash) => {
    const result = window.confirm(`Do you want to continue: ${option}?`);
    if (!result) {
      setAnchorEl(null);
      return;
    }

    setLoading(true);
    try {
      if (option === 'Delete') {
        await httpProvider.delete(`/api/bookmarks/${hash}`, {
          headers: { 'Authorization': token }
        });
        setAlertInfo({ message: 'Bookmark deleted successfully', severity: 'warning' });
        navigate('/bookmarks');
      } else if (option === 'Read Aloud') {
        await handleItemRead();
      }
    } catch (error) {
      setAlertInfo({ message: error.message, severity: 'error' });
    } finally {
      setLoading(false);
      setAnchorEl(null);
    }
  };

  const handleItemRead = async () => {
    const API_KEY = `${process.env.REACT_APP_ELEVENLABS_API_KEY}`;
    const VOICE_ID = 'Yko7PKHZNXotIFUBG7I9';
    const FORMAT = 'mp3_44100_64';

    const headers = {
      "Content-Type": "application/json",
      "xi-api-key": API_KEY,
    };

    const requestBody = {
      text: item.bookmark.summary,
      voice_settings: { stability: 1, similarity_boost: 1 },
    };

    try {
      const response = await axios.post(
        `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}?output_format=${FORMAT}`,
        requestBody, {
        headers,
        responseType: "blob",
      });

      if (response.status === 200) {
        const itemAudio = new Audio(URL.createObjectURL(response.data));
        itemAudio.play();
      }
    } catch (error) {
      setAlertInfo({ message: error.message, severity: 'error' });
    } finally {
      setLoading(false);
      setPlayAudio(true);
    }
  };

  const handleTagClick = (tag) => {
    navigate(`/bookmarks?search=${tag}`);
  };

  useEffect(() => {
    fetchBookmark();
  }, [fetchBookmark, reloadFlag]); // added reloadFlag to dependencies

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ margin: 1 }}>

      <Breadcrumb breadcrumbs={breadcrumbs} />

      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

      {loading && <ThreeDotsLoader />}

      {!loading && item && (
        <Grid item xs={12}>

          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" component="div">
              {item.bookmark.title}
            </Typography>
            <Typography variant="caption" display="block">
              {item.bookmark.publish_date && `Publish Date: ${item.bookmark.publish_date} | `}
              {item.bookmark.author && `Author: ${item.bookmark.author} | `}
              {item.bookmark.language && `Language: ${item.bookmark.language}`}
            </Typography>
            <Grid item xs={10} sx={{ marginTop: 2 }}>
              {item.bookmark.tags.split(',').map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  variant="outlined"
                  sx={{ margin: "2px" }}
                  onClick={() => handleTagClick(tag)}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <h3>Summary:</h3>
              <Typography>{item.bookmark.summary}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <CardMedia
                component="img"
                alt={item.bookmark.title}
                height="250"
                image={item.bookmark.image}
              />
            </Grid>
          </Grid>

          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Details" {...a11yProps(0)} />
              <Tab label="Edit" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>

            <Grid container>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                  <Button title="visit" target="_blank" size="small" href={item.bookmark.url}>Visit</Button>
                  <Button title="Previous" size="small" href={`/bookmarks/${item.prev_hash}`}>Previous</Button>
                  <Button title="Next" size="small" href={`/bookmarks/${item.next_hash}`}>Next</Button>
                  <Box display="flex">
                    <AudioLoader
                      height="20"
                      width="20"
                      color="#35baf6"
                      ariaLabel="audio"
                      visible={playAudio}
                    />
                  </Box>
                  <Box display="flex">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={Boolean(anchorEl) ? 'long-menu' : undefined}
                      aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClickMenu}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{ 'aria-labelledby': 'long-button' }}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      {options.map((option) => (
                        <MenuItem key={option} onClick={() => handleCloseMenu(option, item.hash)}>
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <Markdown className="markdown">{item.bookmark.markdown}</Markdown>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Grid container>
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <UpdateBookmarkForm initialBookmarkData={item.bookmark} onUpdate={handleUpdateBookmark} />
              </Grid>
            </Grid>
          </CustomTabPanel>
        </Grid>
      )}
    </Grid>
  );
};

export default Bookmark;
