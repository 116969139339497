import React, { useEffect, useState } from 'react';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Container, Typography, Grid, TextField, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';


const CACHE_DURATION = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

const CurrencyExchangeRate = ({ currencyCode }) => {
    const tableType = 'A';

    const [exchangeRates, setExchangeRates] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().subtract(365, 'days'));
    const [endDate, setEndDate] = useState(dayjs());
    const [latestRate, setLatestRate] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchWithCache = async (url, cacheKey) => {
        const cached = localStorage.getItem(cacheKey);
        if (cached) {
            const { data, timestamp } = JSON.parse(cached);
            if (Date.now() - timestamp < CACHE_DURATION) {
                return data;
            }
        }

        const response = await fetch(url);
        const data = await response.json();

        if (data && data.date && !isNaN(Date.parse(data.date))) {
            localStorage.setItem(cacheKey, JSON.stringify({ data, timestamp: Date.now() }));
        }

        return data;
    };

    const fetchExchangeRates = async (start, end) => {
        const startDateString = start.format('YYYY-MM-DD');
        const endDateString = end.format('YYYY-MM-DD');
        const cacheKey = `exchangeRates_${currencyCode}_${startDateString}_${endDateString}`;

        try {
            setLoading(true);
            const data = await fetchWithCache(`https://api.nbp.pl/api/exchangerates/rates/${tableType}/${currencyCode}/${startDateString}/${endDateString}/?format=json`, cacheKey);
            if (data.rates) {
                const rates = data.rates.map(rate => ({
                    date: rate.effectiveDate,
                    value: rate.mid,  // Assuming 'mid' field contains the exchange rate value
                }));
                setExchangeRates(rates);
                setLoading(false);
            } else {
                console.error('Error: No rates data in response');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching exchange rates:', error);
            setLoading(false);
        }
    };

    const fetchLatestRate = async () => {
        const cacheKey = `latestRate_${currencyCode}`;

        try {
            const data = await fetchWithCache(`https://api.nbp.pl/api/exchangerates/rates/${tableType}/${currencyCode}/?format=json`, cacheKey);
            if (data.rates && data.rates.length > 0) {
                setLatestRate(data.rates[0].mid);
            } else {
                setError('Error: No latest rate data in response');
            }
        } catch (error) {
            setError('Error fetching latest rate:', error);
        }
    };

    const handleUpdateClick = () => {
        fetchExchangeRates(startDate, endDate);
    };

    const formatCurrency = (value) => {
        return parseFloat(value).toFixed(2) + ' PLN';
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${formatCurrency(payload[0].value)} | ${label}`}</p>
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        fetchExchangeRates(startDate, endDate);
        fetchLatestRate();
    }, [currencyCode]); // Only run on mount and when currencyCode changes

    return (
        <Container sx={{ marginBottom: 4 }}>
            {latestRate !== null && (
                <Typography variant="h6">
                    <small>{currencyCode} Rate:</small> {formatCurrency(latestRate)}
                </Typography>
            )}
            <Grid container rowSpacing={2} sx={{ marginTop: 2 }} alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12} sm={3}>
                        <MobileDatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newDate) => setStartDate(newDate)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <MobileDatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newDate) => setEndDate(newDate)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <LoadingButton
                            loading={loading}
                            onClick={handleUpdateClick}>
                            {loading && <HourglassEmptyIcon />}
                            {!loading && "Update Data"}
                        </LoadingButton>
                    </Grid>
                </LocalizationProvider>
            </Grid >
            <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
                {exchangeRates.length > 0 && (
                    <AreaChart width={600} height={300} data={exchangeRates} style={{ marginTop: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis scale="log" domain={[3.6, 5]} />
                        <Tooltip content={<CustomTooltip />} />
                        <Area type="monotone" dataKey="value" stroke="#006d77" fill="#006d77" />
                    </AreaChart>
                )}
            </ResponsiveContainer>
            {error && <FormHelperText>{error}</FormHelperText>}
        </Container >
    );
};

export default CurrencyExchangeRate;
