
import React from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Area, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomTooltip';

const LightGraph = ({ data }) => {
    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
            <AreaChart
                data={data}
                syncId="anyId">
                <XAxis dataKey="date" />
                <YAxis />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y="5" stroke="#ffba08" label="Dim" />
                <ReferenceLine y="10" stroke="#ffba08" label="Bright" />
                <Area name="Light (%)" type="monotone" dataKey="light" stroke="#ffba08" fill="#ffba08" unit="%" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default LightGraph;

