import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const CaretBreadcrumbs = ({ breadcrumbs }) => {
    return (
        <Grid item xs={12} sx={{ marginBottom: 3 }}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs.map((breadcrumb, index) => (
                    breadcrumb.href ? (
                        <Link key={index} underline="hover" color="inherit" href={breadcrumb.href}>
                            {breadcrumb.label}
                        </Link>
                    ) : (
                        <Typography key={index} color="text.primary">
                            {breadcrumb.label}
                        </Typography>
                    )
                ))}
            </Breadcrumbs>
        </Grid>
    );
};

export default CaretBreadcrumbs;
