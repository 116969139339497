
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const SearchBookmarksForm = ({ searchTerm, onSubmit }) => {
    return (
        <Box component="form" onSubmit={onSubmit} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="search"
                        label="Search"
                        name="search"
                        placeholder={searchTerm}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        label="Search Bookmarks"
                        sx={{ mt: 3, mb: 2 }}>
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SearchBookmarksForm;