import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import httpProvider from '../provider/HttpProvider';
import Breadcrumb from '../components/Breadcrumb';
import CustomAlert from '../components/CustomAlert';
import SearchForm from '../components/forms/SearchForm';
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import TasksGrid from "../components/TasksGrid";
import BookmarksGrid from "../components/BookmarksGrid";

const Search = () => {
    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Search' }
    ];

    const [remainingTasks, setRemainingTasks] = useState([]);
    const [doneTasks, setDoneTasks] = useState([]);
    const [bookmarks, setBookmarks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [token] = useState(localStorage.getItem("token"));
    const [searchTerm, setSearchTerm] = useState(null);
    const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });

    const navigate = useNavigate();
    const { term } = useParams();

    const fetchItemsByTag = (tag) => {
        setLoading(true);

        const remainingTasksUrl = `/api/tasks/?is_completed=false&tag=${tag}&sort=id&sortDesc=true`;
        const doneTasksUrl = `/api/tasks/?is_completed=true&tag=${tag}&sort=id&sortDesc=true`;
        const bookmarksUrl = `/api/bookmarks/?limit=5&tag=${tag}`;

        const remainingTasksPromise = httpProvider.get(remainingTasksUrl, { headers: { 'Authorization': token } });
        const doneTasksPromise = httpProvider.get(doneTasksUrl, { headers: { 'Authorization': token } });
        const bookmarksPromise = httpProvider.get(bookmarksUrl, { headers: { 'Authorization': token } });

        Promise.all([remainingTasksPromise, bookmarksPromise, doneTasksPromise])
            .then((responses) => {
                console.log(responses);
                setRemainingTasks(responses[0].data.rows || []);
                setDoneTasks(responses[2].data.rows || []);
                setBookmarks(responses[1].data.rows || []);
                setLoading(false);
            })
            .catch((error) => {
                setAlertInfo({ message: error.message });
                setLoading(false);
            });
    };

    const handleTagFilterOff = () => {
        setSearchTerm(null);
        setBookmarks([]);
        setRemainingTasks([]);
        setDoneTasks([]);
        navigate('/search', true);
    };

    const handleSearchSubmit = (searchInput) => {
        setSearchTerm(searchInput);
        navigate(`/search/${searchInput}`);
    };

    useEffect(() => {
        if (term) {
            setSearchTerm(term);
            fetchItemsByTag(term);
        }
    }, [term, token]);

    return (
        <Grid container direction="row" justifyContent="flex-start" sx={{ margin: 1 }}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

            <Grid item xs={12}>
                <SearchForm onSubmit={handleSearchSubmit} />
            </Grid>

            {searchTerm && <Grid item xs={12}>
                <Grid container spacing={1} sx={{ marginTop: 2 }}>
                    <Grid item>{searchTerm}</Grid>
                    <Grid item>
                        <SearchOffIcon onClick={handleTagFilterOff} />
                    </Grid>
                </Grid>
            </Grid>}

            <Grid item xs={12}>

                <h3>Remaining Tasks</h3>

                {loading && <ThreeDotsLoader />}

                {!loading && remainingTasks.length === 0 && (
                    <h6>No Remaining Tasks Found.</h6>
                )}

                {!loading && remainingTasks.length > 0 && <TasksGrid tasks={remainingTasks} loading={loading} />}

            </Grid>

            <Grid item xs={12}>

                <h3>Done Tasks</h3>

                {loading && <ThreeDotsLoader />}

                {!loading && doneTasks.length === 0 && (
                    <h6>No Done Tasks Found.</h6>
                )}

                {!loading && doneTasks.length > 0 && <TasksGrid tasks={doneTasks} loading={loading} />}

            </Grid>

            <Grid item xs={12}>

                <h3>Bookmarks</h3>

                {loading && <ThreeDotsLoader />}

                {!loading && bookmarks.length === 0 && (
                    <h6>No Bookmarks Found.</h6>
                )}

                {!loading && bookmarks.length > 0 && <BookmarksGrid bookmarks={bookmarks} loading={loading} />}

            </Grid>
        </Grid>
    );
};

export default Search;
