import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import CustomTooltip from './CustomTooltip';

const PressureGraph = ({ data }) => {
    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
            <BarChart
                data={data}
                syncId="anyId">
                <XAxis dataKey="date" />
                <Legend />
                <YAxis scale="log" domain={[950, 1045]} />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y="1020" stroke="#ffba08" label="1020" />
                <ReferenceLine y="1010" stroke="#ffba08" label="1010" />
                <ReferenceLine y="1005" stroke="#ffba08" label="1005" />
                <Bar name="Pressure (hPa)" type="monotone" dataKey="pressure" stroke="#ffba08" fill="#ffba08" unit="hPa" />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default PressureGraph;
