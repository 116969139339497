import { ThreeDots } from 'react-loader-spinner'
import Grid from '@mui/material/Grid';

const ThreeDotsLoader = () => {
    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={4} sx={{ marginTop: 40 }}>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#35baf6"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </Grid>
    );
};

export default ThreeDotsLoader;
