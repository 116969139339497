import React from "react";
import BookmarkThumb from "./BookmarkThumb";
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';


const BookmarksList = ({ list, onDelete, onChipClick }) => {
  if (!list.items) {
    return <Grid item md={12}>
      <Alert variant="outlined" severity="info" sx={{ width: 1 }}>No results found!</Alert>
    </Grid>;
  }
  return (
    <Grid container item spacing={3}>
      {
        list.items.map((item, index) => {
          return <BookmarkThumb key={index} item={item} onDelete={onDelete} onChipClick={onChipClick} />;
        })
      }
    </Grid>
  );
};

export default BookmarksList;