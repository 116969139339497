import React from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Legend, Area, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomTooltip';

const HallGraph = ({ data }) => {
    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
            <AreaChart
                data={data}
                syncId="anyId">
                <XAxis dataKey="date" />
                <YAxis />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <Area name="Hall (%)" type="monotone" dataKey="hall" stroke="#06d6a0" fill="#06d6a0" unit="%" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default HallGraph;
