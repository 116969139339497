import React from "react";
import Chip from "@mui/material/Chip";
import StyleIcon from "@mui/icons-material/Style";

const TagsComponent = ({ tags, handleTagClick }) => {
    const uniqueTags = [...new Set(tags)];

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginBottom: 10 }}>
            <StyleIcon />
            {uniqueTags.map((value, index) => (
                <Chip
                    key={index}
                    label={value}
                    variant="outlined"
                    sx={{ marginLeft: 2, marginBottom: 1 }}
                    onClick={() => handleTagClick(value)}
                />
            ))}
        </div>
    );
};

export default TagsComponent;
