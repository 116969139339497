import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import AuthProvider from './provider/AuthProvider'
import NetworkDetector from './provider/NetworkDetector'
import Routes from "./routes";

import './App.css'
import '@fontsource/roboto/700.css';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {

  useEffect(() => {
    const handleRouteChange = () => {
      localStorage.setItem('lastVisitedRoute', window.location.pathname);
    };

    handleRouteChange(); // Initial save on component mount

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </div>
  )
}

export default NetworkDetector(App);
