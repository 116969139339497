import React, { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';

import Breadcrumb from '../components/Breadcrumb';
import CustomAlert from '../components/CustomAlert';
import CurrencyExchangeRate from '../components/graphs/CurrencyExchangeRate';
import AIWeather from '../components/AIWeather';
import Stats from '../components/Stats';
import MetalExchangeRate from "../components/graphs/MetalExchangeRate";
import IoInputField from "../components/IoInputField";
import BitcoinPrice from "../components/graphs/BitcoinPrice";

const Dash = () => {

  const breadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'Dash' }
  ];

  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
  const token = localStorage.getItem("token")
  const metalExchangeRateToken = `${process.env.REACT_APP_METALS_API_TOKEN}`;

  useEffect(() => {
  }, [token]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ margin: 1 }} >
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

      <Grid container direction="row" justifyContent="flex-end" spacing={0} alignItems="top">

        <Grid item xs={12} md={8}>
          <BitcoinPrice currencyCode="PLN" />
          <CurrencyExchangeRate currencyCode="EUR" />
          <CurrencyExchangeRate currencyCode="GBP" />
          <CurrencyExchangeRate currencyCode="USD" />
          <MetalExchangeRate token={metalExchangeRateToken} currencyCode="EUR" currencies="XAU" />
          <MetalExchangeRate token={metalExchangeRateToken} currencyCode="EUR" currencies="XAG" />
        </Grid>

        <Grid item xs={12} md={4}>
          <Stats token={token} />
          <AIWeather />
          <IoInputField token={token} />
        </Grid>

      </Grid>
    </Grid>
  )
}

export default Dash
