import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import httpProvider from '../../provider/HttpProvider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const AddBookmarkForm = ({ token, onAddSuccess, onAddError }) => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);

    const isValidUrl = (url) => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    };

    const handleUrlSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if (!isValidUrl(url)) {
            onAddError('URL is invalid');
            setLoading(false);
            return;
        }

        const encodedUrl = btoa(url);
        httpProvider.get(`/api/bookmarks/add?source=web&url=${encodedUrl}`, {
            headers: {
                'Authorization': token,
            }
        })
            .then((response) => {
                setLoading(false);
                onAddSuccess();
            })
            .catch((error) => {
                setLoading(false);
                switch (error.response.status) {
                    case 409:
                        navigate(encodedUrl);
                        break;
                    default:
                        onAddError(error.message);
                }

            });
    };

    useEffect(() => {
        setLoading(false)
    }, []);

    return (
        <Box component="form" onSubmit={handleUrlSubmit} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <TextField
                        id="addUrl"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        type="submit"
                        variant="outlined"
                        label="Add"
                        disabled={loading}
                        sx={{ ml: 2 }}>
                        {loading ? (<HourglassEmptyIcon />) : (<AddCircleOutlineIcon />)}
                    </Button>
                </Grid>
            </Grid >
        </Box >
    );
};

export default AddBookmarkForm;
