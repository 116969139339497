import React, { useState } from "react";
import { Typography, Grid } from '@mui/material';

import Cookies from 'js-cookie';
import LoadingButton from '@mui/lab/LoadingButton';

import httpProvider from "../provider/HttpProvider";
import CustomAlert from './CustomAlert';

const AIWeather = () => {
    const [weather, setWeather] = useState(null);
    const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
    const [loadingWeather, setLoadingWeather] = useState(false);
    const token = localStorage.getItem("token");

    const fetchWeather = () => {
        setLoadingWeather(true);
        httpProvider
            .post('/api/openai/weather', {
                "city": "Berlin,DE",
                "lat": "52.520008",
                "lon": "13.404954"
            }, {
                headers: {
                    'Authorization': token,
                }
            })
            .then((response) => {
                setWeather(response.message);
                if (response.message.hasOwnProperty('temperature') && response.message.temperature !== null) {
                    Cookies.set('weather', JSON.stringify(response.message));
                }
                setLoadingWeather(false);
            })
            .catch((error) => {
                setLoadingWeather(false);
                setAlertInfo({ message: error.message });
            });
    };

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
            <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />

            {!loadingWeather && weather && (
                <Grid container direction="column">
                    <Grid item xs={12}>
                        <Typography variant="button" display="block" gutterBottom>Temperature:</Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'light' }} display="block" gutterBottom>{weather.temperature} °C</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline" display="block" gutterBottom>Wind: </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'light' }} display="block" gutterBottom>{weather.wind} m/s</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline" display="block" gutterBottom>Recommended: </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'light' }} display="block" gutterBottom>{weather.recommendation}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline" display="block" gutterBottom>Summary: </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'light' }} display="block" gutterBottom>{weather.summary}</Typography>
                    </Grid>
                </Grid>)
            }

            {
                weather == null && (
                    <Grid container direction="column">
                        <LoadingButton loading={loadingWeather} variant="contained" onClick={fetchWeather}>Get AI Weather</LoadingButton>
                    </Grid>
                )
            }
        </Grid >
    );
};

export default AIWeather;
