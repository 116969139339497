import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";

import Grid from '@mui/material/Grid';

import httpProvider from "../provider/HttpProvider";
import BookmarksList from "../components/BookmarksList";
import Breadcrumb from '../components/Breadcrumb';
import CustomAlert from '../components/CustomAlert';
import SearchBookmarksForm from '../components/forms/SearchBookmarksForm';
import TablePagination from '../components/TablePagination';
import ThreeDotsLoader from '../components/ThreeDotsLoader';
import AddBookmarkForm from '../components/forms/AddBookmarkForm';

const Bookmarks = () => {

  const breadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'Bookmarks' }
  ];

  const section = "Bookmarks"
  const DEFAULT_PER_PAGE = 50
  const DEFAULT_PAGE = 0
  const DEFAULT_SEARCH_TERM = ''

  const navigate = useNavigate();
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const [currentPage, setCurrentPage] = useState(
    urlParams.get('page') ? parseInt(urlParams.get('page')) : DEFAULT_PAGE
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    urlParams.get('size') ? parseInt(urlParams.get('size')) : DEFAULT_PER_PAGE
  );
  const [searchTerm, setSearchTerm] = useState(
    urlParams.get('search') ? urlParams.get('search') : DEFAULT_SEARCH_TERM
  );
  const [pageItems, setPageItems] = useState([]);
  const [token] = useState(getToken())
  const [alertInfo, setAlertInfo] = useState({ message: '', severity: 'info' });
  const [pagination, setPagination] = useState({
    "count": 0,
    "rowsPerPage": rowsPerPage,
    "page": currentPage,
  });

  const handleSearch = (term) => {
    let url = `/bookmarks/?page=${currentPage}&limit=${rowsPerPage}&search=${term}`
    if (term !== searchTerm) {
      setLoading(true)
      setSearchTerm(term)
      setPagination({
        "rowsPerPage": DEFAULT_PER_PAGE,
        "page": DEFAULT_PAGE,
      })
      url = `/bookmarks/?page=${DEFAULT_PAGE}&limit=${DEFAULT_PER_PAGE}&search=${term}`;
    }
    navigate(url)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const term = (new FormData(event.target)).get('search')
    handleSearch(term)
  };

  const handleChipClick = (term) => {
    navigate(`/bookmarks/?page=${DEFAULT_PAGE}&limit=${DEFAULT_PER_PAGE}&search=${term}`);
    navigate(0)
  }

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setCurrentPage(newPage);
    navigate(`/bookmarks?page=${newPage}&limit=${rowsPerPage}&search=${searchTerm}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleRemoveItem = (item) => {
    setLoading(true)
    httpProvider.delete(`/api/bookmarks/${item.hash}`, {
      headers: {
        'Authorization': token,
      }
    }).then((response) => {
      setLoading(false);
      setAlertInfo({ message: 'Updated', severity: 'warning' });
    }).catch((error) => {
      setLoading(false);
      setAlertInfo({ message: error.message });
    });

    const updatedDataItems = pageItems[0].items.filter((dataItem) => dataItem.id !== item.id);

    setPageItems([{
      "section": section,
      "items": updatedDataItems
    }]);
    setPagination({
      "count": pagination.count - 1,
      "rowsPerPage": pagination.rowsPerPage,
      "page": pagination.page
    })
    setLoading(false);
  };

  const handleAddSuccess = () => {
    setLoading(true);
    window.location.reload(false);
  };

  const handleAddError = (message) => {
    setLoading(false);
    setAlertInfo({ message, severity: 'warning' });
  };

  useEffect(() => {
    setPageItems([{ "items": [] }])
    setPagination({
      "page": DEFAULT_PAGE,
      "rowsPerPage": DEFAULT_PER_PAGE,
      "count": 0,
    })

    let url = `/api/bookmarks/?limit=${rowsPerPage}&page=${currentPage}&sort=id&sortDesc=true`
    if (searchTerm.length > 0) {
      url = `/api/bookmarks/search?limit=${rowsPerPage}&page=${currentPage}&term=${searchTerm}&sort=id&sortDesc=true`
    }

    httpProvider
      .get(url, {
        headers: {
          'Authorization': token,
        }
      })
      .then((response) => {
        setPageItems([{ "section": section, "items": response.data.rows }])
        setPagination({
          "count": response.data.total_rows,
          "rowsPerPage": response.data.limit ? response.data.limit : 0,
          "page": response.data.page ? response.data.page : DEFAULT_PAGE,
        })
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.status) {
          switch (error.response.status) {
            case 400:
              setLoading(false)
              setAlertInfo({ message: 'Search term needs to be at least 2 characters long.', severity: 'warning' });
              break;
            case 404:
              setLoading(false)
              setAlertInfo({ message: 'No results Found.', severity: 'warning' });
              break;
            default:
              setLoading(false)
              setAlertInfo({ message: error.message, severity: 'warning' });
          }
        } else {
          setLoading(false)
          setAlertInfo({ message: error.message });
        }
      });
  }, [currentPage, rowsPerPage, searchTerm, token, alertInfo]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ margin: 1 }} >
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <CustomAlert message={alertInfo.message} severity={alertInfo.severity} />
      <Grid item xs={12}>
        <SearchBookmarksForm searchTerm={searchTerm} handleSubmit={handleSubmit} />
      </Grid>
      <Grid item xs={12}>
        {loading && <ThreeDotsLoader />}
        {!loading &&
          pageItems.map((list, index) => (
            <div key={index}>
              <Grid item xs={11} sx={{ marginTop: 5, marginBottom: 5 }}>
                <AddBookmarkForm token={token} onAddSuccess={handleAddSuccess} onAddError={handleAddError} />
              </Grid>
              <TablePagination pagination={pagination} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} loading={loading} />
              <BookmarksList list={list} onDelete={handleRemoveItem} onChipClick={handleChipClick} />
              <TablePagination pagination={pagination} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} loading={loading} />
            </div>
          ))}
      </Grid>
    </Grid>
  );
};

export default Bookmarks;
