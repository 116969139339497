import React from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Area, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomTooltip';

const AirHumidityGraph = ({ data }) => {
    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
            <AreaChart
                data={data}
                syncId="anyId">
                <XAxis dataKey="date" />\
                <YAxis />
                <Legend />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine y="40" stroke="#2a9d8f" label="Dry" />
                <ReferenceLine y="65" stroke="#2a9d8f" label="Humid" />
                <Area name="Air Humidity (%)" type="monotone" dataKey="humidity" stroke="#2a9d8f" fill="#2a9d8f" unit="%" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default AirHumidityGraph;
